/*=========================================================================================
// INICIO MAIN JS
========================================================================================= */
jQuery(function ($) {
    $(document).ready(function () {
      var SITE_URL = $(".navbar a.w-nav-brand").attr("href"),
        AJAX_URL = SITE_URL + "/wp-admin/admin-ajax.php",
        LOGIN_URL = SITE_URL + "/area-do-cliente";
     
        
  
  /*=========================================================================================
  //LOGIN
  =========================================================================================*/
  
  $('#area_cliente').submit(function() {
    var data    = {
        'action'  : 'getUser',
        'login'   : $('#login').val(),
        'senha'   : $('#password').val(),
    }
  
    ajax_call(data);
  });
  
  function ajax_call(data){
    $.ajax({
        url: AJAX_URL,
        type: "POST",
        data: data,
        beforeSend: function() {
            $('.login_msg').attr('class','login_msg');
            $('#cliente-login-load').removeClass('hidden');
            $('#area_cliente .btn').attr('disabled','disabled');
        },
        success: function(response) {
  
            var rsp = response;
            console.log('rsp: '+rsp);
  
            if(rsp == 1) {
                var msg = "Login realizado com sucesso! Iremos redirecioná-lo para a área de clientes.",
                    css = 'login__msg--sucesso';
                    window.location.href = LOGIN_URL;
            } else if(rsp == 2) {
                var msg = "Dados incorretos. Tente novamente",
                    css = 'login__msg--erro';
            } else {
                var msg = "Nosso sistema está em manutenção. Favor tente novamente em alguns minutos.",
                    css = 'login__msg--erro';
            }
  
            $('.login_msg').delay(200).addClass(css).html(msg).fadeIn(500);
            $('.login_msg').delay(1300).fadeOut(800);
            $('#area_cliente .btn').removeAttr('disabled');
            $('#cliente-login-load').addClass('hidden');
        },
        error: function() {
            var msg = 'Nosso sistema está com problemas. Favor tente novamente em alguns minutos.',
                css = 'login_msg--erro';
                $('.login_msg').delay(200).addClass(css).html(msg).fadeIn(500);
                $('.login_msg').delay(1300).fadeOut(800);
                $('#area_cliente .btn').removeAttr('disabled');
                $('#cliente-login-load').addClass('hidden');
        }
    });
  }
  
  
  
  
  
      /*=========================================================================================
  // FIM MAIN JS
  ========================================================================================= */
    });
  });
  