$('.form-home').submit(function (e) {
    e.preventDefault();
    const nome = $('#nomecompleto').val();
    const email = $('#emailcontato').val();
    const telefone = $('#whatsapptel').val();
    const nameEmp = $("#meuEmp option:selected").text();

    if (nameEmp == "M.Lar Jacarey"){
    var codCvc = 4;
    } else if(nameEmp == "M.Lar Sul"){
    var codCvc = 3;
    } else if(nameEmp == "M.Lar Kennedy"){
    var codCvc = 23;
    }else {
        // Se nameEmp não for nenhum dos valores acima, não execute o segundo bloco
        executeSecondBlock = false;
    }

    var data = {
    "email": email,
    "telefone": telefone,
    "telefone_ddi": "+55",
    "nome": nome,
    "midia": "Site Mlar Empreendimentos",
    "permitir_alteracao": true,
    "origem": "SI",
    "idempreendimento": codCvc,
    "tags": [
        "Formulário de Contato",
        nameEmp
    ],
    "produto": [
        nameEmp
    ]
    }
    console.log(data);

    $.ajax({
        url: 'https://marquise.cvcrm.com.br/api/cvio/lead/', // caminho para o script que vai processar os dados
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        headers: {
            email: 'stephanie@convertte.com.br',
            token: '5945ff147b0d0f11653337b0ead9a3e091b8882b	'
        },
        data: JSON.stringify(data),
        success: function (response) {
            $('.erro-message').hide();
            $('.sucesso-message').show(700);
        },
        error: function (xhr, status, error) {
            $('.erro-message').show(700);
            alert('erro ao enviar a mensagem '+xhr.responseText);
        }
    });
    return false;
});

$('.form-emp').submit(function (e) {
    e.preventDefault();
    const nome = $('#emp_nome').val();
    const email = $('#emp_email').val();
    const telefone = $('#emp_tel').val();
    var nameEmp = document.getElementById('empDataCvc').getAttribute('data-emp');
    var codCvc = document.getElementById('empDataCvc').getAttribute('data-codcvc');


    var data = {
      "email": email,
      "telefone": telefone,
      "telefone_ddi": "+55",
      "nome": nome,
      "midia": "Site Mlar Empreendimentos",
      "permitir_alteracao": true,
      "origem": "SI",
      "idempreendimento": codCvc,
      "tags": [
          "Formulário de Contato",
          nameEmp
      ],
      "produto": [
          nameEmp
      ]
    }
    console.log(data);

    $.ajax({
        url: 'https://marquise.cvcrm.com.br/api/cvio/lead/', // caminho para o script que vai processar os dados
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        headers: {
            email: 'stephanie@convertte.com.br',
            token: '5945ff147b0d0f11653337b0ead9a3e091b8882b	'
        },
        data: JSON.stringify(data),
        success: function (response) {
            $('.erro-message').hide();
            $('.sucesso-message').show(700);
        },
        error: function (xhr, status, error) {
            $('.erro-message').show(700);
            alert('erro ao enviar a mensagem '+xhr.responseText);
        }
    });
    return false;
});