import './form.js';
import './ajax.js';

/*=========================================================================================
// INICIO MAIN JS
========================================================================================= */
jQuery(function ($) {
  $(document).ready(function () {

    



    //TOGGLE MOBILE MENU

    $(".accordion-item-title").on("click", function () {
      $(this).next().toggleClass("open");

      // $(this).next().addClass('active');
    });
    $(".sandwich-btn-menu").on("click", function () {
      if ($(".mobile-menu ").hasClass("opem")) {
        $(".mobile-menu ").removeClass("opem");
        $(".ml-main ").removeClass("opem");
      } else {
        $(".mobile-menu ").addClass("opem");
        $(".ml-main ").addClass("opem");
      }
    });
    $(".mobile-menu .mob-nav a").on("click", function () {
      $(".mobile-menu ").removeClass("opem");
      $(".ml-main ").removeClass("opem");
    });

    $(window).on("resize", function () {
      if ($(this).width() > 991) {
        $(".mobile-menu ").removeClass("opem");
        $(".ml-main ").removeClass("opem");
      }
    });

    document.addEventListener('wpcf7mailsent', function (event) {

      if ('87' == event.detail.contactFormId) { 
        
        location = 'https://www.mlarempreendimentos.com.br/sucesso/';

      }
    
    }, false);
    //popup home

    $('[data-fancybox]').fancybox({
      youtube : {
          controls : 0,
          showinfo : 0
      },
      vimeo : {
          color : 'f00'
      }
  })


  // owl carousel

  function slideDestaque() {
    // SET OWL - DESTAQUE
    $(".owl-slider-destaque").owlCarousel({
      items: 1,
      nav: true,
      dots: false,
      loop: true,
      mouseDrag: true,
      margin: 0,
      autoplay: true,
      autoplayTimeout: 8000,
      autoplayHoverPause: true,
      navText: false,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1,
          nav: true,
          dots: false
        },
        1000: {
          items: 1,
          nav: true,
          dots: false
        }
      }
    });
  }slideDestaque()


    //Slide

    $(".ml-slides").slick({
      infinite: true,
      arrows: true,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
    });
    $(".vantagens_slide").slick({
      infinite: true,
      arrows: true,
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: false,
      centerMode: true,
      centerPadding: "0",

      responsive: [
        {
          breakpoint: 660,
          settings: {
            centerPadding: "60px",
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    
    $(".slider-banner-mlar-sul").slick({
      infinite: true,
      arrows: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
    });

    var galleryLeght = $(".gallery_slide .figure-item").length - 1;
    $(".gallery_slide").slick({
      infinite: true,
      arrows: true,
      dots: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      adaptiveHeight: true,
      centerMode: true,
      centerPadding: "0",

      responsive: [
        {
          breakpoint: 660,
          settings: {
            centerPadding: "60px",
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    $(".gallery_slide").on("afterChange",function (event, slick, currentSlide) {

        console.log(galleryLeght);
        if (currentSlide === 0) {
          console.log("First element");
          $(".btn-nav-gallery").addClass("first-slide-is-active");
        } else if (currentSlide === galleryLeght) {
          $(".btn-nav-gallery").addClass("last-slide-is-active");
          console.log("Last element");
        } else {
          $(".btn-nav-gallery").removeClass("last-slide-is-active");
          $(".btn-nav-gallery").removeClass("first-slide-is-active");
        }
      }
    );

    $(".btn-nav-gallery .arrow-left").on("click", function () {
      $(".gallery_slide").slick("slickPrev");
    });
    $(".btn-nav-gallery .arrow-right").on("click", function () {
      $(".gallery_slide").slick("slickNext");
    });

    $(".btn-nav-obras .arrow-left").on("click", function () {
      $(".gallery_slide").slick("slickPrev");
    });
    $(".btn-nav-obras .arrow-right").on("click", function () {
      $(".gallery_slide").slick("slickNext");
    });

    var plantasLeght = $(".plantas_slide .figure-item").length - 1;
    console.log(plantasLeght);
    $(".plantas_slide").slick({
      infinite: false,
      arrows: false,
      dots: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      adaptiveHeight: true,
      centerMode: true,
      centerPadding: "0",

      responsive: [
        {
          breakpoint: 660,
          settings: {
            centerPadding: "60px",
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    $(".plantas_slide").on(
      "afterChange",
      function (event, slick, currentSlide) {
        if (currentSlide === 0) {
          console.log("First element");
          $(".btn-nav-plantas").addClass("first-slide-is-active");
        } else if (currentSlide === plantasLeght) {
          $(".btn-nav-plantas").addClass("last-slide-is-active");
          console.log("Last element");
        } else {
          $(".btn-nav-plantas").removeClass("last-slide-is-active");
          $(".btn-nav-plantas").removeClass("first-slide-is-active");
        }
      }
    );

    $(".btn-nav-plantas .arrow-left").on("click", function () {
      $(".plantas_slide").slick("slickPrev");
    });
    $(".btn-nav-plantas .arrow-right").on("click", function () {
      $(".plantas_slide").slick("slickNext");
    });
    $(".btn-nav-plantas .arrow-right").on("click", function () {
      $(".plantas_slide").slick("slickNext");
    });


    // Init fancybox
    var indexSlide;
    $().fancybox({
      selector: ".gallery_slide .slick-slide:not(.slick-cloned) a",

      beforeClose: function () {
        indexSlide = $(".fancybox-thumbs-active").attr("data-index");
      },
      afterClose: function () {
        setTimeout(() => {
          $(".gallery_slide").slick("slickGoTo", indexSlide);
        }, 100);
        setTimeout(() => {
          $(".gallery_slide").slick("refresh");
        }, 100);
      },
      animationEffect: "zoom-in-out",
      thumbs: {
        autoStart: true
      }
    });

    // Init fancybox
    $().fancybox({
      selector: ".plantas_slide .slick-slide:not(.slick-cloned) a",
      thumbs: {
        autoStart: true
      }
    });
    // Init fancybox
    $().fancybox({
      selector: "a.item.slick-slide:not(.slick-cloned)",
      thumbs: {
        autoStart: true
      }
    });

    $(window).scroll(detectScroll);

    function detectScroll() {
      // var tempScrollTop = $(window).scrollTop();
      // if (tempScrollTop > 100) {
      //   $(".navigation-container").addClass("redu");
      //   $("#mascote-emi").addClass("show");
      // } else {
      //   $(".navigation-container").removeClass("redu");
      //   $("#mascote-emi").removeClass("show");
      //   $("#mascote-emi").removeClass("upper");
      //   $("#scrollbar").removeClass("open");
      // }
    }

    function squarefy(selector) {
      var width = new Array();

      // Loop to get all element heights
      $(selector).each(function () {
        // Need to let sizes be whatever they want so no overflow on resize
        $(this).css("min-height", "0");
        $(this).css("max-height", "none");
        $(this).css("height", "auto");

        // Then add size (no units) to array
        width.push($(this).width());
      });

      // Find max height of all elements
      var max = Math.min.apply(Math, width);

      // Set all heights to max height
      $(selector).each(function () {
        $(this).css("height", max + "px");
      });
    }

    $(window).on("load resize", function () {
      squarefy(".grid-gallery figure");
      // squarefy('.sv-produto__foto-grid .item');
    });

    function mascaraForm() {
      $("#telefone").mask("(99) 99999-9999");
      $("#fone").mask("(99) 99999-9999");
      $("#emp_interesse_tel").mask("(99) 99999-9999");

      $(".phone-mask-pattern").mask("(99) 99999-9999");
    }

    function close_accordion_section(target) {
      target.removeClass("active");
      target.next().removeClass("open");
      target.next().slideUp();
    }

    function accordionAreaCliente() {
      // ACORDION CONTRATOS - AREA DO CLIENTE
      var barActive = $(".accordion-title");

      $(barActive.data("arrow")).slideDown();

      $(".accordion-title").click(function (e) {
        e.preventDefault();

        var currentAttrValue = $(this).data("arrow");

        if ($(e.target).is(".active")) {
          close_accordion_section($(e.target));
        } else {
          $(this).addClass("active");

          $(".accordion " + currentAttrValue).slideDown();
        }
      });
    }

    // OPÇÕES NO PAINEL DO CLIENTE
      // Ao clicar no mascote
    $("#mascote-emi").on("click", function () {
        // Alterna a classe "open" no #scrollbar
        $("#scrollbar").toggleClass("open");

        // Alterna a classe "upper" no mascote
        $(this).toggleClass("upper");
    });

    $("#drop3, #drop4").on("click", function (e) {
        e.preventDefault();
        console.log("Dropdown clicado");
        var subMenu = $(this).parent().children("#ir-menu, #boleto");
        $(subMenu).slideToggle();
    });
    
    $(".dropdown-info").on("click", function (e) {
      if($(this).hasClass("active") || $(this).next(".accordion-content").hasClass("open")){
        $(this).removeClass("active");
        $(this).next(".accordion-content").removeClass("open");
      } else{
        $(this).addClass("active");
        $(this).next(".accordion-content").addClass("open");
      }
    });

    // Show the first tab and hide the rest
    $('#tabs-nav li:first-child').addClass('active');
    $('.tab-content').hide();
    $('.tab-content:first').show();

    // Click function
    $('#tabs-nav li').click(function(){
      $('#tabs-nav li').removeClass('active');
      $(this).addClass('active');
      $('.tab-content').hide();
      
      var activeTab = $(this).find('a').attr('href');
      $(activeTab).fadeIn();
      return false;
    });

    /*=========================================================================================
    // FIM MAIN JS
    ========================================================================================= */
  });
});